(function($) {

	/*--------------------------------------------------------------------------
	| SCROLL TO TOP
	|--------------------------------------------------------------------------*/
	// Scroll to top button appear
	$(document).scroll(function() {
		$(this).scrollTop() > 100 ? $('a.scroll-to-top').fadeIn() : $('a.scroll-to-top').fadeOut();
	});
	// animated scrolling
	$(document).on('click', 'a.scroll-to-top', function(e) {
		var $anchor = $(this);
		$('html, body').stop().animate({ scrollTop: ($($anchor.attr('href')).offset().top) }, 'fast');
		e.preventDefault();
	});

	/*--------------------------------------------------------------------------
	| FULLSCREEN
	|--------------------------------------------------------------------------*/
	function fullscreenToggle() {
		var a = $(window).height() - 10;

		if (!document.fullscreenElement && // alternative standard method
			!document.mozFullScreenElement && !document.webkitFullscreenElement) { // current working methods
			if (document.documentElement.requestFullscreen) {
				document.documentElement.requestFullscreen();
			} else if (document.documentElement.mozRequestFullScreen) {
				document.documentElement.mozRequestFullScreen();
			} else if (document.documentElement.webkitRequestFullscreen) {
				document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
			}
		} else {
			if (document.cancelFullScreen) {
				document.cancelFullScreen();
			} else if (document.mozCancelFullScreen) {
				document.mozCancelFullScreen();
			} else if (document.webkitCancelFullScreen) {
				document.webkitCancelFullScreen();
			}
		}
	}
	
	$('#fullscreenToggle').on('click', function(e) {
		e.preventDefault();
		fullscreenToggle();
		// $(this).find('i').toggleClass('fa-bars fa-list')
		$(this).find('i').toggleClass('fa-expand fa-compress');
	});




	// /*--------------------------------------------------------------------------
	// | DATATABLE HELPERS
	// |--------------------------------------------------------------------------*/
	// // When the checkbox within the table header is clicked, check all rows shown within the table
	// $('body').on('change', 'input[name="tbl-select-all"]', function() {
	// 	var to, rows, checked;
	// 	to = $(this).data('to-table');
	// 	rows = $('.table-' + to).find('tbody tr');
	// 	checked = $(this).prop('checked');
	// 	$.each(rows, function() {
	// 		var checkbox = $($(this).find('td').eq(0)).find('input').prop('checked', checked);
	// 	});
	// });



	// /*--------------------------------------------------------------------------
	// | ADD HOTKEYS - key, function
	// |--------------------------------------------------------------------------*/
	// add_hotkey('Shift+D', function() {window.location.href = base_url + 'dashboard'});
	// add_hotkey('Shift+C', function() {window.location.href = base_url + 'contacts/search'});
	// add_hotkey('Shift+A', function() {window.location.href = base_url + 'appointments'});
	// add_hotkey('Shift+F', function() {window.location.href = base_url + 'followups'});
	// add_hotkey('Shift+M', function() {window.location.href = base_url + 'messages'});
	// add_hotkey('Shift+R', function() {window.location.href = base_url + 'contacts/addreferrals'});
	// add_hotkey('Shift+L', function() {window.location.href = base_url + 'contacts/lead/create'});
	



	/*--------------------------------------------------------------------------
	| INDICATE THAT ALL JS HAS LOADED
	|--------------------------------------------------------------------------*/
	$('body').addClass('jsloaded');

})(jQuery);// End dom ready


/*--------------------------------------------------------------------------
| JQUERY EXTENSIONS
|--------------------------------------------------------------------------*/
// https://www.sanwebe.com/snippet/simple-done-typing-jquery-function
(function($) {
	$.fn.donetyping = function(callback){
		var _this = $(this);
		var x_timer;    
		_this.keyup(function (){
			clearTimeout(x_timer);
			x_timer = setTimeout(clear_timer, 1000);
		});

		function clear_timer(){
			clearTimeout(x_timer);
			callback.call(_this);
		}
	}
})(jQuery);



/*--------------------------------------------------------------------------
| HELPER FUNCTIONS
|--------------------------------------------------------------------------*/
function confirm_delete() {
    var e = "Are you sure you want to perform this action?";
    return "undefined" != typeof app && (e = app.lang.confirm_action_prompt), 0 != confirm(e)
}

function slugify(e) {
    return e.toString().trim().toLowerCase().replace(/\s+/g, "-").replace(/[^\w\-]+/g, "").replace(/\-\-+/g, "-").replace(/^-+/, "").replace(/-+$/, "")
}

function stripTags(e) {
    var t = document.createElement("DIV");
    return t.innerHTML = e, t.textContent || t.innerText || ""
}

function empty(e) {
    if ("number" == typeof e || "boolean" == typeof e) return !1;
    if (void 0 === e || null === e) return !0;
    if (void 0 !== e.length) return 0 === e.length;
    var t = 0;
    for (var a in e) e.hasOwnProperty(a) && t++;
    return 0 === t
}

function _ajax(_url, _data={}, _type='get', _datatype='json') {
	return $.ajax({
		url: _url,
		type: _type,
		dataType: _datatype,
		data: _data,
	});
}